import React from 'react'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ToggleCheckbox } from 'components'

const propTypes = {
  partnershipQuestion: Types.partnershipQuestion.isRequired,
  ...formPropTypes,
}

const defaultProps = {}

function PartnershipQuestionForm({ handleSubmit, partnershipQuestion }) {
  const { label, description } = partnershipQuestion
  const labelId = 'partnershipQuestion-label'
  const descriptionId = description ? 'partnershipQuestion-description' : null

  return (
    <div className="flex-horizontal flex-center flex-gap-5 flex-space-between">
      <div>
        <h2 id={labelId}>{label}</h2>
        {description && <p id={descriptionId}>{description}</p>}
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <Field
          name="openToPartnerships"
          component={ToggleCheckbox}
          label={false}
          ariaLabelledby={labelId}
          ariaDescribedby={descriptionId}
          className="toggle-checkbox"
          // submit form on every change
          // timeout needed to submit with new value
          onChange={() => setTimeout(handleSubmit, 0)}
        />
      </form>
    </div>
  )
}

PartnershipQuestionForm.propTypes = propTypes
PartnershipQuestionForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-partnership-question',
  })
)(PartnershipQuestionForm)
