import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Field, FormSection } from 'redux-form'
import { Input, SubmitButton } from 'lp-components'
import { validateNested, useCommunity } from 'utils'
import { SectionBlock, SectionHeader } from 'components'
import { startCase, get, camelCase } from 'lodash'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    contact: PropTypes.shape({
      position: PropTypes.string.isRequired,
      positionId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

function OrganizationPositionContactForm({
  handleSubmit,
  submitting,
  initialValues: { contact },
  form, // eslint-disable-line react/prop-types
}) {
  const community = useCommunity()
  // Form can be used multiple times on the same screen so need to have unique ids
  const uniqueId = useCallback((inputName) => `${form}-${inputName}`, [form])

  return (
    <form onSubmit={handleSubmit}>
      <SectionBlock>
        <SectionHeader>
          <h3 id={uniqueId('header')}>
            {get(
              community,
              `displayText.partnerPortal.profile.contacts.${camelCase(
                contact.position
              )}`,
              startCase(contact.position)
            )}
          </h3>
        </SectionHeader>
        <fieldset
          className="contact-fields outer"
          aria-labelledby={uniqueId('header')}
        >
          <FormSection name="contact">
            <Field
              id={uniqueId('firstName')}
              name="firstName"
              component={Input}
            />
            <Field
              id={uniqueId('lastName')}
              name="lastName"
              component={Input}
            />
            <Field
              id={uniqueId('title')}
              name="title"
              label="Job Title"
              component={Input}
            />
            <Field id={uniqueId('phone')} name="phone" component={Input} />
            <Field id={uniqueId('email')} name="email" component={Input} />
          </FormSection>
        </fieldset>
        <SubmitButton submitting={submitting}>Save</SubmitButton>
      </SectionBlock>
    </form>
  )
}

OrganizationPositionContactForm.propTypes = propTypes
OrganizationPositionContactForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'partner-organization-contacts',
    enableReinitialize: true,
    submitFilters: {
      allow: ['id', 'contact'],
    },
    validate: validateNested({
      'contact.firstName': { presence: true },
      'contact.lastName': { presence: true },
      'contact.phone': {
        presence: true,
        format: {
          pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: 'must be a valid phone number',
        },
      },
      'contact.email': { presence: true, email: true },
    }),
  })
)(OrganizationPositionContactForm)
