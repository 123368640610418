import logo from 'images/communities/Michigan/logo.svg'
import footerLogoOne from 'images/communities/Michigan/MAC-Logo-white_NO-tag.svg'
import footerLogoTwo from 'images/communities/Michigan/MAEIA-logo_color.svg'
import footerLogoThree from 'images/communities/Michigan/MACC-Logo-black-and-white.svg'
import footerLogoFour from 'images/communities/Michigan/MI-DOE-Logo.svg'
import dashboardHeaderImage from 'images/communities/Michigan/banner.svg'
import favicon from 'images/communities/Michigan/favicon.png'
import homeLogo from 'images/home/community-logos/michigan.png'
import loginBackgroundImg from 'images/communities/Michigan/login-background-image.svg'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'michigan',
  name: 'Michigan',
  position: 12,
  partnerSupportEmail: 'artlook@MICreativePotential.org',
  schoolSupportEmail: 'artlook@MICreativePotential.org',
  stylesheet: 'michigan.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.micreativepotential.org ',
  faqUrl:
    'https://drive.google.com/file/d/19otJjh-pMh-QiI89kFMje96ApF4sPcC_/view?usp=sharing',
  additionalUrls: [
    {
      src: 'https://maeia-artsednetwork.org/',
      title: 'MAEIA Resources',
    },
  ],
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  dataDashboardEnabled: true,
  notificationsEnabled: true,
  educatorEnabled: false,
  id: 80,
}

// Search
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  map: {
    center: { lat: 44.314806, lng: -85.602389 },
    radius: 250, // in miles
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'openToPartnerships',
    'region',
  ],
}

// Branding
config.defaultBranding = {
  className: 'michigan-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Michigan Creative Potential's hompage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Michigan Creative Potential',
  },
  footerLogos: [
    {
      src: footerLogoOne,
      alt: 'Michigan Assessment Consortium',
      link: 'https://www.michiganassessmentconsortium.org/',
    },
    {
      src: footerLogoTwo,
      alt: 'maeia',
      link: 'https://maeia-artsednetwork.org/',
    },
    {
      src: footerLogoThree,
      alt: 'Michigan Arts & Culture Council',
      link: 'https://www.michiganbusiness.org/industries/macc/',
    },
    {
      src: footerLogoFour,
      alt: 'Michigan Department of Education',
      link: 'https://www.michigan.gov/mde',
    },
  ],
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: dashboardHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: advancing arts education in Michigan',
    searchHeader: 'Find Schools & Partners in Michigan',
    welcomeSearchHeader: 'Explore Arts Education in Michigan',
    welcomeHeader: `artlook<sup>®</sup> Michigan`,
    welcomeMessage: `
      <p>
        Become part of MI Creative Potential's collective impact initiative and help create a comprehensive picture of the arts education landscape in our state. We are expanding arts education in our local, regional, and statewide communities. Join artlook<sup>®</sup> Michigan to advance arts education for every child, in every grade, in every school.
      </p>
      <p>We use artlook<sup>®</sup> Michigan to:</p>
      <p>
        <ul class="bulleted-list">
          <li>share data and glean insights</li>
          <li>connect prospective collaborators</li>
          <li>measure growth and support continuous improvement.</li>
        </ul>
      </p>
      <p>
        MI Creative Potential advances equitable access to quality arts education in Michigan. With support from: <a href="https://www.michigan.gov/mde" target="_blank" rel="noopener norefrrer">Michigan Department of Education</a>, <a href="https://www.michiganassessmentconsortium.org/" target="_blank" rel="noopener norefrrer">Michigan Assessment Consortium</a>, <a href="https://www.michiganbusiness.org/industries/macc/" target="_blank" rel="noopener norefrrer">Michigan Arts & Culture Council</a>, and the <a href="https://www.arts.gov/" target="_blank" rel="noopener norefrrer">National Endowment for the Arts</a>.
      </p>
      <p>
        The <a href="https://maeia-artsednetwork.org/" target="_blank" rel="noopener norefrrer">Michigan Arts Education Instruction and Assessment</a> project (MAEIA), a project of the Michigan Assessment Consortium, is embedded within MI Creative Potential. On behalf of the Michigan Department of Education, MAEIA has produced the Michigan Blueprint of a Quality Arts Education, a catalog of 360 performance assessments, instructional resources, guidance for continuous improvement and demonstrating educator effectiveness in the arts, as well as professional learning.
      </p>
      <p>
        Together we can meet our creative potential.
      </p>
    `,
  },
  tagline: 'Together we can advance arts education in Michigan.',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Michigan</em> is YOUR portal to supplement arts education in K-12 schools.</h3>
        <p>Use <em>artlook<sup>®</sup> Michigan</em> to connect to schools seeking collaboration with arts education partners in dance, media arts, music, theatre, and visual arts.</p>
        <p>
          <strong>To get started, follow the link below to complete a partner profile for your teaching artist or organizational arts education residencies and projects.</strong>
        </p>
        <p> Please reach out with questions: <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.</p>
      `,
    },
    profile: {
      contacts: {
        executiveDirector: 'Executive Director/ CEO/ President',
      },
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Michigan</em> is YOUR portal to supplement arts education in K-12 schools.</h3>
        <p>
          Use <em>artlook<sup>®</sup> Michigan</em> to connect to teaching artists and community arts education providers available to collaborate in dance, media arts, music, theatre, and visual arts education.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p> Please reach out with questions: <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>

      `,
    },
    summary: {
      artsLiaison: {
        header: 'School Arts Liaison',
        tooltip:
          'The Arts Liaison advocates for and facilitates arts education by serving as the primary communicator between their school, arts organizations, and the community. The Arts Liaison acts as the artlook administrator for profile upkeep and the annual survey, ensuring access to arts-related information, supporting artistic initiatives, and working to enhance arts programming in their schools.',
      },
      partnerProgramTypes: {
        header:
          'What types of partner programs would you be interested in having for your school? Check all that apply.',
      },
    },
  },
  educatorPortal: {
    welcomdMessage: ``,
  },
  survey: {
    getStarted: `
      <h2>Welcome to the MI Creative Schools survey!</h2>
      <p>You will add your school's information to share about your arts education instruction, staffing, community partnerships, and resource needs.</p>
      <p>Please save frequently to avoid losing data. You can complete your survey in multiple sessions; saving as you respond to each question will allow you to return to edit your survey before submitting.</p>
      <p>Your data, collected together with schools from across the state, will advance access to quality arts education in Michigan's schools.</p>
    `,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold an ISBE certification or endorsement in one of the four state-recognized disciplines (visual arts, music, theater, and/or dance). Charter schools, contract schools, and Pre-K-only schools do not have this requirement. The media arts discipline does not have an ISBE certification/endorsement; these instructors should be listed as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
    staffingModal: ``,
    courses: {
      classMoniker: '',
      coursesNote: ``,
      minutesNotes: ``,
      instructionalDepthNote: ``,
    },
    percentAccess: {
      question: '',
      description: '',
      courseEnrollmentLabel: '',
      error: '',
    },
    obstaclesSelect: {
      question: '',
    },
    terms: ``,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----
// Confirm with the Community that these are the correctly enabled Survey Questions

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
