import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './external-link'
import { useCommunity } from 'utils'
import { CommunityImage, FooterLegalese, MultipleLogoBlock } from 'components'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

function Footer() {
  const community = useCommunity()
  const secondaryFooterLogo = community.defaultBranding.secondaryFooterLogo
  const supportEmail = community.partnerSupportEmail || 'info@artlookmap.com'
  const renderFooterLogo =
    secondaryFooterLogo && community.subdomain !== 'alabama'
  const isMichigan = community.subdomain == 'michigan'

  return (
    <footer>
      <div className="footer-block-container">
        <div className="footer-links">
          <ul className="primary-nav">
            {/* Disable until generic FAQs are produced
              <li><ExternalLink href="/faq">FAQ</ExternalLink></li>
            */}
            <li>
              <ExternalLink href="https://artlookmap.com">
                What is artlook?
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href={`mailto:${supportEmail}`}>
                Contact
              </ExternalLink>
            </li>
          </ul>
        </div>
        <div className="footer-links-secondary">
          <FooterLegalese />
        </div>
      </div>
      {isMichigan && (
        <MultipleLogoBlock logos={community.defaultBranding.footerLogos} />
      )}
      {renderFooterLogo && (
        <div className="logo-block">
          <CommunityImage image={secondaryFooterLogo} />
        </div>
      )}
    </footer>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer
