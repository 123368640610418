import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { logException } from 'sentry'
import SideNav from './side-nav'
import {
  Content,
  FlashMessageContainer,
  ExternalLink,
  AccountMenu,
  Modal,
  RenderedHtml,
  FooterLegalese,
  NotificationsMenu,
} from 'components'
import { NotificationsPreferencesForm } from 'forms'
import { onError } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { replace } from 'react-router-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as globalPartnerSelectors } from 'partner-portal-reducer'
import {
  updateDocumentTitle,
  useCommunity,
  useCommunityText,
  getCurrentEmployment,
  displaySubmitFailure,
  distinctEmploymentPositions,
  initializeAuthenticatedUserflow,
} from 'utils'
import { Spinner } from 'lp-components'
import { PARTNER_PORTAL_ROUTE } from 'config'
import { kebabCase } from 'lodash'
import classnames from 'classnames'
import menuIcon from 'images/menu.svg'

const propTypes = {
  children: PropTypes.node,
  currentPartnerId: PropTypes.number.isRequired,
  fetchPartner: PropTypes.func.isRequired,
  fetchActiveSchoolYears: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  flashMessages: PropTypes.arrayOf(flashActions.flashMessageType),
  isAdmin: PropTypes.bool,
  userDetails: Types.user,
  redirectToTerms: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  redirectToSignOut: PropTypes.func.isRequired,
  currentSchoolYear: Types.schoolYear.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(Types.notification),
  fetchNotificationPreferences: PropTypes.func.isRequired,
  updateNotificationPreferences: PropTypes.func.isRequired,
  notificationPreferences: Types.notificationPreferences,
}

const defaultProps = {
  children: null,
  flashMessages: [],
  isAdmin: false,
  userDetails: null,
}

function LayoutAuthorized({
  children,
  currentPartnerId,
  fetchPartner,
  fetchActiveSchoolYears,
  flashMessages,
  fetchUserDetails,
  isAdmin,
  userDetails,
  redirectToTerms,
  location,
  redirectToSignOut,
  currentSchoolYear,
  fetchNotifications,
  markAllAsRead,
  notifications,
  fetchNotificationPreferences,
  notificationPreferences,
  updateNotificationPreferences,
}) {
  const community = useCommunity()
  const t = useCommunityText()
  const terms = t('terms')
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [holdForRedirect, setHoldForRedirect] = useState(!!terms)
  const showNotificationsMenu = community.notificationsEnabled

  useEffect(() => {
    fetchPartner(currentPartnerId)
  }, [currentPartnerId])

  useEffect(() => {
    fetchNotifications()
  }, [])

  useEffect(() => {
    if (userDetails?.notificationSubscriptionId) {
      fetchNotificationPreferences(userDetails.notificationSubscriptionId)
    }
  }, [userDetails?.notificationSubscriptionId])

  useEffect(() => {
    fetchActiveSchoolYears({ organizationId: currentPartnerId })
  }, [currentPartnerId])

  useEffect(() => {
    if (!userDetails) fetchUserDetails()
  }, [])

  useEffect(() => {
    if (terms && hasLoaded) {
      if (!userDetails.agreedAt && !location.pathname.includes('sign-out')) {
        redirectToTerms()
      } else {
        setHoldForRedirect(false)
      }
    }
  }, [userDetails, hasLoaded, terms])

  const currentEmployment = useMemo(() => {
    if (!userDetails) return
    return getCurrentEmployment({
      employments: userDetails.employments,
      currentEmployableId: currentPartnerId,
      currentEmployableType: Types.EMPLOYABLE_TYPES.ORGANIZATION,
    })
  }, [userDetails, currentPartnerId])

  const employmentPositions = userDetails
    ? distinctEmploymentPositions(userDetails.employments)
    : []

  const hasLoaded = !!userDetails

  // if a current employment wasn't found, the user is not correctly signed in
  if (hasLoaded && !currentEmployment) redirectToSignOut()

  initializeAuthenticatedUserflow(userDetails)

  const [navVisibleOnMobile, setNavVisibleOnMobile] = useState(false)
  const closeMobileNav = () => {
    setNavVisibleOnMobile(false)
    return true
  }

  return (
    <div className={kebabCase(Types.PORTAL.PARTNER_PORTAL)}>
      <a href="#content" id="skip-nav-link">
        Skip to Main Content
      </a>
      <button
        type="button"
        className="nav-toggle"
        aria-expanded={navVisibleOnMobile}
        aria-controls="side-navigation-content"
        onClick={() => setNavVisibleOnMobile(true)}
        aria-label="Open Navigation"
      >
        <img src={menuIcon} alt="" />
      </button>
      <div
        className={classnames('nav-container', {
          'is-active': navVisibleOnMobile,
        })}
      >
        <button
          type="button"
          className="close"
          aria-label="Close Navigation"
          onClick={closeMobileNav}
        >
          ×
        </button>
        <SideNav
          community={community}
          currentSchoolYear={currentSchoolYear}
          closeMobileNav={closeMobileNav}
          partnerId={currentPartnerId}
        />
      </div>
      {!hasLoaded || holdForRedirect ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {isAdmin && (
            <div className="flash-message admin failure">
              <p>You are viewing the portal as an admin</p>
            </div>
          )}
          <FlashMessageContainer messages={flashMessages} />
          <Content id="content" className="portal-wrapper">
            {showNotificationsMenu && notificationPreferences && (
              <NotificationsMenu
                openPreferencesModal={(show) => setShowPreferencesModal(show)}
                notifications={notifications}
                markAllAsRead={markAllAsRead}
              />
            )}
            <AccountMenu
              currentEmployment={currentEmployment}
              currentEmployableType={Types.EMPLOYABLE_TYPES.ORGANIZATION}
              employments={userDetails.employments}
              userProfile={userDetails.profile}
              userEmail={userDetails.email}
            />
            {children}
            <footer className="portal-footer">
              <FooterLegalese
                hasTerms={!!terms}
                openTermsModal={() => setShowTermsModal(true)}
              />
              <p className="contact">
                Email{' '}
                <ExternalLink href={`mailto:${community.partnerSupportEmail}`}>
                  {community.partnerSupportEmail}
                </ExternalLink>{' '}
                with questions.
              </p>
            </footer>
            {showTermsModal && (
              <Modal onClose={() => setShowTermsModal(false)}>
                <h2>Terms & Conditions</h2>
                <div className="modal-content">
                  <RenderedHtml>{terms}</RenderedHtml>
                </div>
              </Modal>
            )}
            {showPreferencesModal && (
              <Modal onClose={() => setShowPreferencesModal(false)}>
                <NotificationsPreferencesForm
                  employmentPositions={employmentPositions}
                  initialValues={notificationPreferences}
                  onSubmit={(params) =>
                    updateNotificationPreferences(
                      notificationPreferences.id,
                      params
                    )
                  }
                  onSubmitSuccess={() => setShowPreferencesModal(false)}
                  onSubmitFail={displaySubmitFailure}
                  closePreferencesModal={() => setShowPreferencesModal(false)}
                />
              </Modal>
            )}
          </Content>
        </React.Fragment>
      )}
    </div>
  )
}

LayoutAuthorized.propTypes = propTypes
LayoutAuthorized.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPartnerId: globalPartnerSelectors.currentPartnerId(state),
    userDetails: globalPartnerSelectors.userDetails(state),
    flashMessages: flashActions.getFlashMessages(state),
    isAdmin: globalPartnerSelectors.isAdmin(state),
    partner: globalPartnerSelectors.partner(state),
    notifications: globalPartnerSelectors.notifications(state),
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    notificationPreferences:
      globalPartnerSelectors.notificationPreferences(state),
  }
}

const mapDispatchToProps = {
  fetchPartner: apiActions.fetchPartnerAuthorized,
  fetchActiveSchoolYears: apiActions.fetchActiveSchoolYears,
  fetchUserDetails: apiActions.fetchCurrentPartnerUserDetails,
  fetchNotifications: apiActions.fetchNotifications,
  fetchNotificationPreferences: apiActions.fetchNotificationPreferences,
  updateNotificationPreferences: apiActions.updateNotificationPreferences,
  markAllAsRead: apiActions.markAllAsRead,
  redirectToTerms: () => replace(PARTNER_PORTAL_ROUTE + '/terms'),
  redirectToSignOut: () => replace(PARTNER_PORTAL_ROUTE + '/sign-out'),
}

function updateTitle({ partner }) {
  const title = partner ? `${partner.name} - ` : ''
  return `${title}Arts Partner | artlook`
}

function onComponentDidCatch(_, error, errorInfo) {
  return logException(error, errorInfo)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  updateDocumentTitle(updateTitle),
  onError(onComponentDidCatch)
)(LayoutAuthorized)
