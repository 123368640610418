import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Card,
  Content,
  ProfileMapDisplay,
  ExternalLink,
  PhoneLink,
  SearchResultContactLink,
  SchoolYearNav,
  OpenToPartnershipsIndicator,
} from 'components'
import { PartnerContent } from '../components'
import { connectQuery } from 'lp-hoc'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import * as Types from 'types'
import { Element } from 'react-scroll'
import locationImg from 'images/map/dashboards/location.svg'
import phoneImg from 'images/map/dashboards/phone.svg'
import websiteImg from 'images/map/dashboards/web.svg'
import contactImg from 'images/map/dashboards/contact.svg'
import reportImg from 'images/map/dashboards/report.svg'
import * as routerActions from 'react-router-redux'
import { buildInternalApiUrl, toAddressString } from 'utils'
import { first, get, size, some } from 'lodash'
import { Spinner } from 'lp-components'

const propTypes = {
  clearPartner: PropTypes.func.isRequired,
  fetchPartner: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  partner: Types.partner,
  partnerLocation: Types.location,
  partnerLocations: PropTypes.arrayOf(Types.location),
  redirectToNotFound: PropTypes.func.isRequired,
  year: PropTypes.number,
  schoolYears: PropTypes.arrayOf(Types.schoolYear),
  fetchActiveAndPublishedSchoolYears: PropTypes.func.isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
}

const defaultPropTypes = {
  partner: null,
  partnerLocation: null,
  schoolYears: null,
}

// Small helper to ignore "" and " " values returned by the API (handles undefined and null values)
const hasFullName = ({ fullName }) => !!(fullName || '').trim()

function Partner({
  clearPartner,
  fetchPartner,
  partnerLocations,
  isLoading,
  partnerLocation,
  partner,
  redirectToNotFound,
  year,
  schoolYears,
  fetchActiveAndPublishedSchoolYears,
  programTypes,
}) {
  const selectedSchoolYearId = year || get(first(schoolYears), 'id')
  function getSchoolYearNumber(id) {
    return schoolYears.find((schoolYear) => schoolYear.id === id).number
  }

  useEffect(() => {
    fetchActiveAndPublishedSchoolYears()
  }, [])

  useEffect(() => {
    if (!selectedSchoolYearId) return
    fetchPartner(selectedSchoolYearId).catch((e) => {
      if (e && e.status === 404) return redirectToNotFound()
    })
  }, [selectedSchoolYearId])

  useEffect(() => {
    if (!schoolYears) return
    if (!some(schoolYears, ({ id }) => id === selectedSchoolYearId))
      redirectToNotFound()
  }, [schoolYears, selectedSchoolYearId])

  // Only clear selected partner once (on unmount)
  useEffect(() => clearPartner, [])

  if (!schoolYears) return <Spinner />

  const numSchoolYears = size(schoolYears)
  const contact = get(partner, 'contact') || {}
  const schoolYearNumber = getSchoolYearNumber(selectedSchoolYearId)

  // Allow layout and header to render with loading condition inside of content component
  return (
    <Content className="partner-page">
      {/* Header */}
      <header className="profile-details-block grey-background">
        <div className="map-page-header interior-page" />
        <Element
          className="header-block content-block-container"
          name="details"
        >
          {!partner && <div className="header-padding" />}
          {partner && (
            <div>
              {numSchoolYears !== 0 && (
                <SchoolYearNav
                  baseUrl={`/partners/${partner.id}`}
                  message="Showing Partner data for school year:"
                  schoolYears={schoolYears}
                  selectedSchoolYearId={selectedSchoolYearId}
                />
              )}
              <Card>
                <div className="show-header">
                  {partner.logo && (
                    <div className="image-thumbnail">
                      <img
                        src={partner.logo.src}
                        alt={partner.logo.alt || ''}
                      />
                    </div>
                  )}
                  <div className="text-block">
                    <h1 data-cy="partner-header">{partner.name}</h1>
                    <ul className="details">
                      {partner.address && (
                        <li>
                          <img src={locationImg} alt="Address" />
                          <p>{toAddressString(partner.address)}</p>
                        </li>
                      )}
                      {partner.detail && partner.detail.phone && (
                        <li>
                          <img src={phoneImg} alt="Phone" />
                          <p>
                            <PhoneLink phoneNumber={partner.detail.phone} />
                          </p>
                        </li>
                      )}
                      {partner.detail && partner.detail.website && (
                        <li>
                          <img src={websiteImg} alt="Website" />
                          <p>
                            <ExternalLink href={partner.detail.website}>
                              Partner's Website
                            </ExternalLink>
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="options">
                    {partner.openToPartnerships && (
                      <OpenToPartnershipsIndicator />
                    )}
                    {contact.email && (
                      <SearchResultContactLink
                        email={contact.email}
                        resultName={partner.name}
                        contactName={contact.firstName}
                      >
                        <button type="button" className="button-alert">
                          Contact Partner
                        </button>
                      </SearchResultContactLink>
                    )}
                  </div>
                </div>
                <div className="header-details">
                  <div className="text-block">
                    {partner.detail && partner.detail.description && (
                      <p>{partner.detail.description}</p>
                    )}
                    <ul className="details-block">
                      {contact && (hasFullName(contact) || contact.email) && (
                        <li>
                          <img src={contactImg} alt="Contact" />
                          <p>
                            Primary Contact: {partner.contact.fullName} <br />
                            {contact.email && (
                              <SearchResultContactLink
                                email={contact.email}
                                resultName={partner.name}
                                contactName={contact.firstName}
                              >
                                {contact.email}
                              </SearchResultContactLink>
                            )}
                          </p>
                        </li>
                      )}
                      {
                        <li>
                          <img src={reportImg} alt="Partner Report" />
                          <a
                            href={buildInternalApiUrl({
                              url: `/partners/${partner.id}/report.pdf`,
                              query: { schoolYear: selectedSchoolYearId },
                            })}
                          >
	                    Download {schoolYearNumber} Partner Report
                          </a>
                        </li>
                      }
                    </ul>
                  </div>
                  <div className="map-block">
                    <ProfileMapDisplay
                      location={partnerLocation}
                      zoom={10}
                      hidePopup
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Element>
      </header>

      {/* Main Content */}
      <PartnerContent
        partner={partner}
        isLoading={isLoading}
        partnerLocations={partnerLocations}
        partnerLocation={partnerLocation}
        selectedSchoolYearId={selectedSchoolYearId}
        programTypes={programTypes}
      />
    </Content>
  )
}

Partner.propTypes = propTypes
Partner.defaultPropTypes = defaultPropTypes

function mapStateToProps(state) {
  return {
    partner: selectors.partner(state),
    isLoading: apiSelectors.isLoading(state, apiActions.fetchPartner),
    partnerLocations: selectors.partnerLocations(state),
    partnerLocation: selectors.currentPartnerLocation(state),
    schoolYears: selectors.orderedSchoolYears(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
  }
}

function mapDispatchToProps(dispatch, { params: { id } }) {
  return {
    clearPartner: () => dispatch(actions.clearPartner()),
    fetchPartner: (schoolYearId) =>
      dispatch(apiActions.fetchPartner(id, schoolYearId)),
    redirectToNotFound: () => dispatch(routerActions.replace('/not-found')),
    fetchActiveAndPublishedSchoolYears: () =>
      dispatch(
        apiActions.fetchActiveAndPublishedSchoolYears({ organizationId: id })
      ),
  }
}

export default compose(
  connectQuery('year'),
  connect(mapStateToProps, mapDispatchToProps)
)(Partner)
