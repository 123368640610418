import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import {
  CommunityBackgroundImage,
  CommunityImage,
  FlashMessageContainer,
  MultipleLogoBlock,
} from 'components'
import { updateDocumentTitle, useCommunity, useCommunityText } from 'utils'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  flashMessages: PropTypes.arrayOf(flashActions.flashMessageType),
}

const defaultProps = {
  children: null,
  flashMessages: [],
}

function LayoutUnauthorized({ children, flashMessages }) {
  const community = useCommunity()
  const t = useCommunityText()
  const {
    communityLogo,
    secondaryFooterLogo,
    secondarySideBySideLogo,
    thirdSideBySideLogo,
    secondSubLogo,
    loginBackgroundImg,
    footerLogos,
  } = community.defaultBranding

  return (
    <div>
      <main className="authentication">
        <div className="image-block">
          <CommunityBackgroundImage
            className="image-block-inner"
            image={loginBackgroundImg}
          >
            <div
              className={classnames('logo-block', {
                'side-by-side': secondarySideBySideLogo,
              })}
            >
              <CommunityImage className="primary-logo" image={communityLogo} />
              {secondarySideBySideLogo && (
                <div className="second-logo">
                  <CommunityImage image={secondarySideBySideLogo} />
                </div>
              )}

              {thirdSideBySideLogo && !secondSubLogo && (
                <div className="third-logo">
                  <CommunityImage image={thirdSideBySideLogo} />
                </div>
              )}
            </div>
            <h1>{t('tagline')}</h1>
            <div className="logo-block page-bottom">
              {secondaryFooterLogo && (
                <CommunityImage image={secondaryFooterLogo} />
              )}
              {secondSubLogo && <CommunityImage image={secondSubLogo} />}
              {footerLogos && (
                <MultipleLogoBlock
                  logos={footerLogos}
                  className="footer-logos"
                />
              )}
            </div>
          </CommunityBackgroundImage>
        </div>
        <FlashMessageContainer
          className="unauthorized"
          messages={flashMessages}
        />
        {children}
      </main>
      <footer>
        {footerLogos && <MultipleLogoBlock logos={footerLogos} />}
      </footer>
    </div>
  )
}

LayoutUnauthorized.propTypes = propTypes
LayoutUnauthorized.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: flashActions.getFlashMessages(state),
  }
}

export default compose(
  connect(mapStateToProps, null),
  updateDocumentTitle('Arts Partner | artlook')
)(LayoutUnauthorized)
