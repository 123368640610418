import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
// import { format } from 'react-string-format';
import { waitFor, onMount, modifyProps } from 'lp-hoc'
import { Link } from 'react-router'
import * as globalActions from 'school-portal-actions'
import {
  SectionBlock,
  LoadingSpinner,
  SchoolYearTabBar,
  InfoModal,
  // CommunityQuestion,
} from 'components'
import {
  SurveyStepNav,
  CustomQuestions,
  ScholarshipsQuestion,
} from '../components'
import { PartnershipTableForm, ArtsEngagementForm } from '../forms'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import { SCHOOL_PORTAL_ROUTE } from 'config'
import * as flashActions from 'redux-flash'
import back from 'images/back.svg'
import { sortBy, isEmpty, get } from 'lodash'
import {
  displaySubmitFailure,
  filterQuestionsByPageAndDisplayComponent,
  useDynamicInfoModal,
  findQuestionByPageAndDisplayComponent,
  useCommunity,
  useCommunityText,
  useCommunitySurveyQuestionOption,
  showQuestion,
} from 'utils'

const propTypes = {
  surveySchoolYear: Types.schoolYear.isRequired,
  school: Types.school.isRequired,
  currentSchoolYearTab: PropTypes.number.isRequired,
  setCurrentSchoolYearTab: PropTypes.func.isRequired,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion),
  updateSchool: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  activeSchoolYears: PropTypes.arrayOf(Types.schoolYear).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType),
  engagementTypes: PropTypes.arrayOf(Types.engagementType),
  surveyQuestions: PropTypes.arrayOf(Types.surveyQuestion).isRequired,
  definedDisciplines: PropTypes.arrayOf(Types.discipline).isRequired,
}

const { COMMUNITY_ENGAGEMENTS } = Types.SURVEY_QUESTIONS
const defaultProps = {}

function CommunityAndPartnerships({
  school,
  currentSchoolYearTab,
  surveySchoolYear,
  setCurrentSchoolYearTab,
  partnerOptions,
  activeSchoolYears,
  programTypes,
  updateSchool,
  onSuccess,
  engagementTypes,
  surveyQuestions,
  definedDisciplines,
}) {
  const { partnerships, engagements, surveyAnswers } = school
  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()
  const isCurrentSchoolYear = currentSchoolYearTab === surveySchoolYear.id
  const partnershipsForSchoolYear = partnerships
    .filter(({ schoolYearId }) => schoolYearId === currentSchoolYearTab)
    .filter(({ _destroy }) => !_destroy)
  const otherPartnerships = partnerships.filter(
    ({ schoolYearId }) => schoolYearId !== currentSchoolYearTab
  )

  const availableYears = useMemo(() => {
    return activeSchoolYears.filter((year) => {
      return year.position <= surveySchoolYear.position
    })
  }, [activeSchoolYears, surveySchoolYear])

  const surveySchoolYearNumber = surveySchoolYear.number

  const questionOption = useCommunitySurveyQuestionOption(
    COMMUNITY_ENGAGEMENTS.NAME
  )
  const getQuestionText = get(questionOption, 'getQuestionText')
  const community = useCommunity()
  const t = useCommunityText()

  const scholarshipsQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.COMMUNITY_AND_PARTNERSHIPS,
    Types.QUESTION_DISPLAY_COMPONENTS.SCHOLARSHIPS_FORM
  )

  const customQuestions = filterQuestionsByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.COMMUNITY_AND_PARTNERSHIPS,
    Types.QUESTION_DISPLAY_COMPONENTS.CUSTOM_QUESTION_FORM
  )

  return (
    <div>
      <SurveyStepNav currentStepIndex={1} />
      <div className="content-block-container">
        <SchoolYearTabBar
          schoolYears={availableYears}
          value={currentSchoolYearTab}
          onChange={setCurrentSchoolYearTab}
        />
        <PartnershipTableForm
          programTypes={programTypes}
          initialValues={{
            partnerships: sortBy(partnershipsForSchoolYear, 'organizationName'),
          }}
          onSubmit={updateSchool}
          onSubmitSuccess={onSuccess}
          onSubmitFail={displaySubmitFailure}
          disabled={!isCurrentSchoolYear}
          schoolYears={availableYears}
          partnerOptions={partnerOptions}
          otherPartnerships={otherPartnerships}
          visibleSchoolYearId={currentSchoolYearTab}
        />
        {isCurrentSchoolYear &&
          showQuestion(
            community,
            COMMUNITY_ENGAGEMENTS.NAME,
            engagementTypes
          ) && (
            <SectionBlock>
              <h3>
                {t('survey.communityEngagement.question', {
                  surveySchoolYearNumber,
                }) || getQuestionText({ surveySchoolYearNumber })}{' '}
              </h3>
              <ArtsEngagementForm
                initialValues={{ engagements }}
                onSubmit={updateSchool}
                onSubmitSuccess={onSuccess}
                engagementTypes={engagementTypes}
              />
            </SectionBlock>
          )}
        {scholarshipsQuestion && (
          <ScholarshipsQuestion
            question={scholarshipsQuestion}
            disciplines={definedDisciplines}
            scholarships={school.scholarships}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        )}
        {!isEmpty(customQuestions) && (
          <CustomQuestions
            surveyQuestions={customQuestions}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
            setShowInfoModalWithContent={setShowInfoModalWithContent}
            surveyAnswers={surveyAnswers}
          />
        )}
      </div>
      <div className="sticky-buttons">
        <Link
          className="link-black"
          to={SCHOOL_PORTAL_ROUTE + '/survey/staffing-and-instruction'}
        >
          <img src={back} alt="" /> Back
        </Link>
        <div className="continue">
          <Link
            className="button-secondary"
            to={SCHOOL_PORTAL_ROUTE + '/survey/budget-and-planning'}
          >
            Continue to Next Page
          </Link>
        </div>
      </div>
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModalWithContent(false)}
          content={infoModalContent.content}
          header={infoModalContent.header}
        />
      )}
    </div>
  )
}

CommunityAndPartnerships.propTypes = propTypes
CommunityAndPartnerships.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    school: globalSchoolSelectors.school(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    surveyAttempt: globalSchoolSelectors.surveyAttempt(state),
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
    activeSchoolYears: globalSchoolSelectors.orderedActiveSchoolYears(state),
    engagementTypes: selectors.engagementTypes(state),
    partnerOptions: globalSchoolSelectors.partnerOptions(state),
    surveyQuestions: selectors.surveyQuestions(state),
    definedDisciplines: globalSelectors.definedDisciplines(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchEngagementTypes: apiActions.fetchEngagementTypes,
  fetchPartnerOptions: apiActions.fetchPartnerOptions,
  updateSchool: apiActions.updateSchool,
  setSchool: globalActions.setSchool,
}

function modify({
  currentSchoolYearTab,
  school,
  setSchool,
  flashSuccessMessage,
  updateSchool,
}) {
  return {
    updateSchool: (params) =>
      updateSchool(school.id, currentSchoolYearTab, params),
    onSuccess: (school) => {
      setSchool(school)
      flashSuccessMessage('Responses saved!')
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount('fetchPartnerOptions'),
  onMount('fetchEngagementTypes'),
  waitFor(
    ['school', 'partnerOptions', 'surveyAttempt', 'engagementTypes'],
    LoadingSpinner
  ),
  withState(
    'currentSchoolYearTab',
    'setCurrentSchoolYearTab',
    ({ surveySchoolYear }) => surveySchoolYear.id
  ),
  modifyProps(modify)
)(CommunityAndPartnerships)
