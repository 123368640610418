import React from 'react'
import {
  LabeledField,
  omitLabelProps,
  hasInputError,
  generateInputErrorId,
} from 'lp-components'
import { filterInvalidDOMProps } from 'lp-components/lib/utils'
import classnames from 'classnames'

function generateAriaDescribedby({ meta, name, ariaDescribedby }) {
  if (hasInputError(meta)) return generateInputErrorId(name)
  return ariaDescribedby || null
}

function ToggleCheckbox(props) {
  const {
    input: { name, value, onChange },
    ariaLabelledby,
    ariaDescribedby,
    meta,
    className,
    ...rest
  } = omitLabelProps(props)
  return (
    <LabeledField
      className={classnames('checkbox toggle-checkbox', {
        className,
        'no-response': value === '',
      })}
      {...props}
    >
      <input
        {...{
          id: name,
          name,
          value,
          type: 'checkbox',
          checked: value,
          onChange: () => onChange(!value),
          'aria-labelledby': ariaLabelledby || null,
          'aria-describedby': generateAriaDescribedby({
            meta,
            name,
            ariaDescribedby,
          }),
          ...filterInvalidDOMProps(rest),
        }}
      />
    </LabeledField>
  )
}

export default ToggleCheckbox
